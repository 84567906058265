<template>
  <div class="hub page">

    <ul>
      <Tile v-for="( link, index ) in links" :key="index" :link="link"/>
    </ul>

  </div>
</template>

<script>
import Tile from '@/components/hub/Tile.vue'

export default {
  name: 'Hub',

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Tile
  },

  computed: {
    links() {
      return this.$route.meta.pages
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.hub {
  padding: $page-padding;

  ul {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    grid-gap: $page-padding;

    @media ( max-width: $mobile ) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
