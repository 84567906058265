<template>
  <li class="hub-tile">
    <router-link :title="title" :to="route">

        <h1>{{ title }}</h1>
        <p>{{ description }}.</p>

        <div class="image" :style="style"></div>

    </router-link>
  </li>
</template>

<script>
export default {
  name: 'HubTile',

  props: {
    link: String
  },

  computed: {
    route() {
      return this.$router.options.lookup( this.link )
    },

    image() {
      return this.$_.get( this.route, 'meta.image' )
    },

    name() {
      return this.$_.get( this.route, 'meta.name' )
    },

    title() {
      return this.$_.get( this.route, 'meta.title.long' )
    },

    description() {
      return this.$_.get( this.route, 'meta.description' )
    },

    style() {
      if ( !this.image ) return null
      return `background-image: url(${ this.image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.hub-tile {
  @include box-shadow( $shadow );
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  background: $gradient-white;

  a {
    padding: 24px;
  }

  h1 {
    color: $dark-grey;
    font-size: 24px;
    position: relative;
    text-align: left;
  }

  p {
    color: $dark-grey;
    font-size: 14px;
    position: relative;
    padding-top: 2px;
  }

  &:hover {
    .image {
      opacity: 1;
      transform: scale( 1.025 );
    }
  }

  .image {
    height: 120px;
    @include image-center;
    margin-top: 16px;
    opacity: $opacity-low;
    transform-origin: center center;
    transition: transform 0.05s ease-in-out;
  }
}
</style>
